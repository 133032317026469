// Odin events are pushed to this array then processed when page unloads
unloadEvents = [];

(function ($) {
  $(window).on('pagehide premiumEvent:fireBeacon', function() {
    var protocol = 'https';
    var path = '/jobs/__record_beacon_with_data';
    var ajax_options = {
      url: protocol + '://' + location.host + path,
      method: 'POST',
      async: false
    };

    // Better OdinReportableEvents for outward facing links
    unloadEvents.forEach(function(eventObject) {
      ajax_options.data = {
        event_name: eventObject.eventName || eventObject,
        data: eventObject.data || {},
      };
      if ('sendBeacon' in navigator) {
        var csrfToken = $('meta[name="csrf-token"]').attr('content');
        var formData  = new FormData();
        formData.append('event_name', ajax_options.data.event_name);
        formData.append('data', JSON.stringify(ajax_options.data.data));
        formData.append('authenticity_token', csrfToken);
        navigator.sendBeacon(ajax_options.url, formData);
      } else {
        ajax_options.data = JSON.stringify(ajax_options.data);
        $.ajax(ajax_options);
      }
    });
    unloadEvents = [];
  });
})(jQuery);
