jQuery(function($) {
  // Each ad source has a different method for scraping its data back out
  // of the rendered source code.  All of these return an array, or []
  // if the ad block was not found.  It is implied in the code that if the
  // parent element of any given block is found, that the parsing of the
  // channel data will work without issue. So if any of these sources
  // were to change the way they render their ad block, this would likely
  // crash.

  function getMediaNet() {
    if (jQuery('script[id^=_mN_dy]').length) {
      return jQuery('script[id^=_mN_dy]').map(function() {
        return jQuery(this).attr("src").split("chnm=")[1].split("&")[0];
      });
    } else {
      return [];
    }
  }

  function getInuvo() {
    if (jQuery('div[class^=ypa] iframe').length) {
      return jQuery('div[class^=ypa] iframe').map(function() {
        return jQuery(this).attr('src');
      }).map(function() {
        return this.split("ty=")[1].split("&")[0];
      });
    } else {
      return [];
    }
  }

  // Go through each of the four possible ad sources and report on the
  // tracking channels for each.

  function debugTrackers() {
    var message = "MediaNet: ";
    var mn = getMediaNet();
    if (mn.length) {
      for (var i = 0; i < mn.length; i++) {
        message += mn[i];
        if (i < mn.length - 1) { message += ", "; }
      }
    } else {
      message += "not present";
    }
    message += "\n\n";

    message += "Inuvo: ";
    var inuvo = getInuvo();
    if (inuvo.length) {
      for (var i = 0; i < inuvo.length; i++) {
        message += inuvo[i];
        if (i < inuvo.length - 1) { message += ", "; }
      }
    } else {
      message += "not present";
    }
    message += "\n\n";
    alert(message);
  }

  $(document).ready(function() {
    if (GetURLParameter('tracking') == 'debug') {
      createCookie('tracking-debug', 1, 1);
    }
    if (GetURLParameter('tracking') == 'off') {
      eraseCookie('tracking-debug');
    }

    // If cookie is set, add a link to the bottom of the page that, when clicked,
    // will report on the various ad tracking channels that might be present
    // on the page.
    if (readCookie('tracking-debug') == 1) {
      $('body').append('<a href="#" class="debug-info">Debug Info</a>');
      $('a.debug-info').on('click', function() {
        debugTrackers();
      });
    }
  });

  // Helper functions, lifted from around the net

  // Cookie handling
  function createCookie(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";

    document.cookie = name + "=" + value + expires + "; path=/";
  }

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function eraseCookie(name) {
    createCookie(name, "", -1);
  }

  function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  }
});
