// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
window.debounce = debounce;

// Redirects to the given URL
function globalRedirectTo(redirect) {
  if (typeof redirect === 'object' && typeof redirect.responseJSON === 'object' && redirect.responseJSON['session_reset_redirect_uri'] !== undefined && redirect.responseJSON['session_reset_redirect_uri'] !== null && redirect.responseJSON['session_reset_redirect_uri'] !== '') {
    window.location.href = redirect.responseJSON['session_reset_redirect_uri'];
  } else if (typeof redirect === 'object' && redirect['session_reset_redirect_uri'] !== undefined && redirect['session_reset_redirect_uri'] !== null && redirect['session_reset_redirect_uri'] !== '') {
    window.location.href = redirect['session_reset_redirect_uri'];
  } else if (typeof redirect === 'object' && typeof redirect.responseText === 'string') {
    let redirect_obj = {}
    try {
      redirect_obj = JSON.parse(redirect.responseText);
    } catch (error) {
      redirect_obj = {}
    }
    let redirect_uri = redirect_obj['session_reset_redirect_uri'];
    if (redirect_uri !== undefined && redirect_uri !== null && redirect_uri !== '') {
      window.location.href = redirect_uri;
    }
  }
};
window.globalRedirectTo = globalRedirectTo;
