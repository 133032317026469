// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
//

function l5_trk( a )
{
  a.href = a.href+"&TRK=1";
}
window.l5_trk = l5_trk;

function launch_news_clickout(id) {

  var url = $j('#myJN_content_' + id).find('a').first().attr('href');

  var params = 'top=0, left=0';
  params += ', directories=no';
  params += ', location=yes';
  params += ', menubar=yes';
  params += ', resizable=yes';
  params += ', scrollbars=yes';
  params += ', status=yes';
  params += ', toolbar=no';
  params += ', width=1000';
  params += ', height=700';

  newwin = window.open(url, '_blank', params );
  newwin.blur();

  recordEvent('NewsClick', '', '');

  return false;
}
window.launch_news_clickout = launch_news_clickout;

/**
* Stores a job listing info to the modal, then shows the modal
* @param {String} url
*/
function launch_resume_work_history_overlay(url) {
  (function ($) {
    const modal = new mdb.Modal(document.getElementById('resumeWorkHistoryModal'));
    let m = $('#resumeWorkHistoryModal');

    $(m).data("url", url);
    $(document).keyup(function(e) {
      if (e.which == 27 && !modalAlreadyClosed) {
        recordEvent("resumeWorkHistoryModal", "action", "modal_closed_esc_key");
        modalAlreadyClosed = true;
        redirect_after_resume_work_history_overlay();
    }
    });

    $("#resumeWorkHistoryModal").on("show.bs.modal", function() {
      recordEvent("resumeWorkHistoryModal", "action", "modal_opened");
    });

    $(document).on("hide.bs.modal", "#resumeWorkHistoryModal", function() {
      if (!modalAlreadyClosed) {
        modalAlreadyClosed = true;
        recordEvent("resumeWorkHistoryModal", "action", "modal_closed");
        redirect_after_resume_work_history_overlay();
      }
    });

    modal.show();

  })(jQuery);

}
window.launch_resume_work_history_overlay = launch_resume_work_history_overlay;

/**
* On close of the specific modal, if url exists, redirect to it
*/
function redirect_after_resume_work_history_overlay() {
  (function ($) {
    let m = document.getElementById('resumeWorkHistoryModal')
    if (m.length == 0) {
      return;
    }
    let url = $(m).data("url");
    let key = $(m).data("key");
    if (!url) {
      return;
    }
    window.location.href = url;
  })(jQuery);
}
window.redirect_after_resume_work_history_overlay = redirect_after_resume_work_history_overlay;

/**
* Writes job listing key to session when job listing is clicked.
* @param {String} job_listing_key
*/
function writeJobClickToSession(job_listing_key) {
  $j.ajax({
    type: 'GET',
    url: '/jl/write_job_click_to_session',
    data: 'job_listing_key=' + job_listing_key
  });
}
window.writeJobClickToSession = writeJobClickToSession;

function launch_job_clickout(url, id, new_window) {

  var url = $j('a#job_listing_' + id).attr('href');

  var params = 'top=0, left=0';
  params += ', directories=no';
  params += ', location=yes';
  params += ', menubar=yes';
  params += ', resizable=yes';
  params += ', scrollbars=yes';
  params += ', status=yes';
  params += ', toolbar=no';
  params += ', width=1000';
  params += ', height=700';

  if (new_window == 'false') {
    window.location.href = url;
  }
  else {
    newwin = window.open(url, '_blank', params);
  }

  return false;
}
window.launch_job_clickout = launch_job_clickout;

function launch_job_clickout_redirect(url, id, email_clickout_params) {
  if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    if (typeof event != 'undefined' && typeof event.srcElement != 'undefined' && typeof event.srcElement.parentElement != 'undefined'
      && typeof event.srcElement.parentElement.classList != 'undefined' && typeof event.srcElement.parentElement.classList.value != 'undefined') {

      srcElClassName = event.srcElement.parentElement.classList.value;

      if (srcElClassName.includes('fa-circle-info')) {
        return false;
      }
    }
  }

  if (url === null || typeof(url) === 'undefined' || url === '') {
    var url = document.getElementById('job_listing_' + id).href;
  }

  var url = email_clickout_params.redirect_url + '?url=' + encodeURIComponent(url);
  url += '&title=' + encodeURIComponent(email_clickout_params.title);
  url += '&company_name=' + encodeURIComponent(email_clickout_params.company_name);
  url += '&location=' + encodeURIComponent(email_clickout_params.location);
  url += '&onet=' + encodeURIComponent(email_clickout_params.onet_soc);

  var params = 'top=0, left=0';
  params += ', directories=no';
  params += ', location=yes';
  params += ', menubar=yes';
  params += ', resizable=yes';
  params += ', scrollbars=no';
  params += ', status=no';
  params += ', toolbar=no';
  params += ', width=1000';
  params += ', height=700';
  newwin = window.open(url, '_blank', params);
  newwin.blur();

  return false;
}
window.launch_job_clickout_redirect = launch_job_clickout_redirect;

function launch_suggested_job_clickout_redirect(url, id, email_clickout_params) {
  if (url === null || typeof(url) === 'undefined' || url === '') {
    var url = document.getElementById('suggested_job_listing_' + id).href;
  }

  var url = email_clickout_params.redirect_url + '?url=' + encodeURIComponent(url);
  url += '&title=' + encodeURIComponent(email_clickout_params.title);
  url += '&company_name=' + encodeURIComponent(email_clickout_params.company_name);
  url += '&location=' + encodeURIComponent(email_clickout_params.location);
  url += '&onet=' + encodeURIComponent(email_clickout_params.onet_soc);

  var params = 'top=0, left=0';
  params += ', directories=no';
  params += ', location=yes';
  params += ', menubar=yes';
  params += ', resizable=yes';
  params += ', scrollbars=no';
  params += ', status=no';
  params += ', toolbar=no';
  params += ', width=1000';
  params += ', height=700';
  newwin = window.open(url, '_blank', params);
  newwin.blur();

  return false;
}
window.launch_suggested_job_clickout_redirect = launch_suggested_job_clickout_redirect;

function launch_job_clickout_jc_preview_pane(id, suppress_new_win_clickout) {
  var new_window = 'true';
  if (suppress_new_win_clickout == 'true') {
    new_window = 'false';
  }

  var url = document.getElementById('job_listing_' + id).href + '&type=jc_preview_pane';
  var params = 'top=0, left=0';
  params += ', directories=no';
  params += ', location=yes';
  params += ', menubar=yes';
  params += ', resizable=yes';
  params += ', scrollbars=no';
  params += ', status=no';
  params += ', toolbar=no';
  params += ', width=1000';
  params += ', height=700';

  if (new_window == 'false') {
    window.location.href = url;
  }
  else {
    newwin = window.open(url, '_blank', params);
    newwin.blur();
  }

  return false;
}
window.launch_job_clickout_jc_preview_pane = launch_job_clickout_jc_preview_pane;

// launch job in popwin vs new tab
function launch_job_clickout_win(url, id) {

    var url = $j('a#job_listing_' + id).attr('href');

    //detect viewport size
    var w = screen.width - 1000;
    var h = screen.height - 700;

    var params = 'top=' + h + ', left=' + w;
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', directories=no';
    params += ', location=yes';
    params += ', menubar=yes';
    params += ', resizable=yes';
    params += ', toolbar=no';
    params += ', width=1000';
    params += ', height=700';

    newwin = window.open(url, '_blank', params);
    newwin.blur();

    return false;
}
window.launch_job_clickout_win = launch_job_clickout_win;


// replace query term and erase location term in search boxes,
// to be clear with what is being returned in the search results
$j('.popular-search a').on('click', function() {
  query = $j(this).text();
  $j('#headerSearchField_job').val(query);
  $j('#headerSearchField_location').val('');
  displaySpinner();
  recordEvent('PopularSearchClick', 'query', query);
});

$j('.related-positions a').on('click', function() {
  link = this;
  id = $j(this).attr('id');
  if (id == 'all-positions') {
    recordEvent('JobPositionClick', 'link_type', 'all');
  } else {
    recordEvent('JobPositionClick', 'link_type', 'specific');
  }
  $j(link).off('click');
  setTimeout(function() {
    link.click();
  }, 500);
  return false;
});

$j('#salary-chart').on('click', function() {
  recordEvent('SalaryChartClick', '', '');
});

function recordEvent(event_name, value_name, value) {
  if (jQuery.type(value_name) === "string") {
    $j.ajax({
      type: "GET",
      url: "/jobs/__record_event",
      data: "event_name=" + event_name + "&value_name=" + value_name + "&value=" + value
    });
  } else {
    var data = value_name;

    $j.ajax({
      type: "GET",
      url: "/jobs/__record_event",
      data: {
        event_name: event_name,
        data: data
      }
    });
  }
}
window.recordEvent = recordEvent;


function recordExtendedEvent(name, params) {
 var data="event_name="+name;
 var keys = Object.keys(params)
 for (var i in keys) {
   var key = keys[i];
   if (params.hasOwnProperty(key)) {
    data = data.concat("&value_"+key+"="+params[key]);
  }
 }
 $j.ajax({
   type: "GET",
   url: "/jobs/__record_extended_event",
   data: data
 });

}
window.recordExtendedEvent = recordExtendedEvent;
// rails_ujs overrides standard submit events and implements
// its own ajax callbacks
$j('form#search-form')
  .on("ajax:beforeSend", function(evt, xhr, settings) {
    displaySpinner();
    var headerSearchFieldJobValue = $j('#headerSearchField_job').val();
    recordEvent('JobSearch', 'query', encodeURIComponent(headerSearchFieldJobValue));
  });

// smooth scroll back to top of results when paging or clicking popular search links
$j('#jobResultsList').on('click', '.pagination a, .popular-search a', function() {
  scrollToTop();
});

$j('.popular-searches').on('click', scrollToTop);

function scrollToTop() {
  $j('html,body').animate({
    scrollTop: $j('body').offset().top
  }, 500);

  // HACK: the custom certification.js is killing all jQuery animations
  //  hacking in the finished state with native function that doesn't have
  //  smooth scrolling ability
  setTimeout(function () {
    scrollTo(0, 0);
  }, 500);
}
window.scrollToTop = scrollToTop;

function removeBackdrop() {
  $j('body').removeClass('modal-open');
  $j('.modal-backdrop').remove();
}
window.removeBackdrop = removeBackdrop;

function jobsViewedInterval(t) {
  var node = $j('.jobs-viewed .number')
  var number = parseInt(node.text().replace(/,/, ""), 10);
  number++;
  newNumber = number.toString();
  node.text(newNumber.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));

  var intervals = [800, 1000, 1500, 2000, 2200];
  t = intervals[Math.floor(Math.random() * intervals.length)];
  setTimeout(jobsViewedInterval, t);
}
jobsViewedInterval(1000);

setTimeout(function() {
  if ($j('#notification').length > 0) {
    $j('#notification').effect('bounce', { 'distance': 30 }, 800);
  }
}, 1000);

function searchSimilarJobs(query) {
  var hsfLocation = $j('#headerSearchField_location'),
    loc = '';
  if (hsfLocation.length) {
    loc = hsfLocation.val();
  }
  $j('#headerSearchField_job').val(query);
  displaySpinner();
  $j.ajax({
    url: '/jobs/ajax_load_jobs',
    data: 'q=' + encodeURIComponent(query) + '&l=' + loc + '&type=list',
    dataType: 'script'
  });
  scrollToTop();
}

function searchSimilarJobsJI(query) {
    var hsfLocation = $j('#headerSearchField_location'),
            loc = '';
    if (hsfLocation.length) {
        loc = hsfLocation.val();
    }
    $j('#headerSearchField_job').val(query);
    displaySpinner();
    $j.ajax({
        url: '/jobs/ajax_load_ji_jobs',
        data: 'q=' + encodeURIComponent(query) + '&l=' + loc + '&type=list&is_user_requested=true',
        dataType: 'script'
    });
    scrollToTop();
}

function displaySpinner() {
  $j('#job-listings').html('<div class="search-spinner-div"><div class="lds-ring"><div></div><div></div><div></div><div></div></div><div class="load-jobs-text">Loading Jobs...</div></div>');
}
window.displaySpinner = trackEvent;

function revealSalary(key) {
  if (typeof(Storage) !== 'undefined') {
    var revealed_keys = sessionStorage.getItem("revealed_keys");
    var rk;
    if (revealed_keys) {
      rk = JSON.parse(revealed_keys)
    } else {
      rk = [];
    }
    if (!rk.includes(key)) {
      rk.push(key);
      sessionStorage.setItem("revealed_keys", JSON.stringify(rk));
    }
  }
}

function displaySalary(el){
  salary = el.data('salary');
  el.addClass('salary-reveal').text(salary);
}
window.displaySalary = displaySalary;

function closeBsModal(el){
  el.toggle();
  $j('.modal-backdrop').remove();
  $j(document.body).removeClass("modal-open");
}
window.closeBsModal = closeBsModal;


$j(document)
  .on('click', 'a.search-result span.similar', function() {
    var jobTitle = $j(this).parent().find('span.jobResult_title').text();
    var query = $j.trim(jobTitle).split(' ').slice(0,2).join(' ');
    searchSimilarJobs(query);
  })
  .on('click', 'a.search-result span.similar_ji', function() {
    var jobTitle = $j(this).parent().find('span.jobResult_title').text();
    var query = $j.trim(jobTitle).split(' ').slice(0,2).join(' ');
    searchSimilarJobsJI(query);
  })
  .on('click', 'a.search-result', function () {
    if (!$j(this).find('.pre-click-salary').hasClass('hide')) {
      $j(this).find('.pre-click-salary').addClass('hide');
      $j(this).find('.post-click-salary').removeClass('hide');
      revealSalary($j(this).attr('data-key'));
    }
    if ($j(this).find('.search-result__button').hasClass('search-result__button')) {
      $j(this)
        .find('.search-result__button')
        .removeClass('search-result__button')
        .addClass('search-result__button-clicked');
    }
  })
  // smooth scroll back to top of results when paging
  .on('click', 'ul.pagination a, .popular-search.show-for-small', function () {
    displaySpinner();
    scrollToTop();
  })
  .on('click', '.popular-search.show-for-small a, .popular-searches a', function () {
    query = $j(this).text();
    $j('#headerSearchField_job').val(query);
    $j('#headerSearchField_location').val('');
    displaySpinner();
    recordEvent('PopularSearchClick', 'query', query);
  })
  .on('click', '.typo-notification a', function () {
    displaySpinner();
  })
  .on('click', '#inf-scroll', function () {
    $j('#inf-scroll-wrapper').html('<img src="ajax-loading.gif" alt="Ajax Loading" class="loading" />');
  })
  .on('click', '.mobile-search-button, .mobile-search-button-old', function () {
    $j('#search.collapsed').toggleClass('expanded').blur();
  });
