function trackEvent(type, category, action, label) {
  var e = {
    'event': type,
    eventCategory: category,
    eventAction: action,
    eventLabel: label
  };
  
  dataLayer.push(e);
  
}
window.trackEvent = trackEvent;
